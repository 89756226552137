body {
  background: #fff;
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}

.navbar {
  padding: 0;
}
/* Sticky Footer */
/* 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent Chrome, Opera, and Safari from
 *    letting these items shrink to smaller than their content's default
 *    minimum size.
*/
.Site {
  display: flex;
  flex-direction: column;
  height: 100vh; /* 1 */
}

.Site-header,
.Site-footer {
  flex-shrink: 0; /* 2 */
}

.Site-content {
  flex: 1 0 auto; /* 2 */
}

.footer {
  margin-top: 10px;
  padding: 0px 0px 0px 0px;
  // background: url(images/breadcrumbs.png) repeat;
  color: darkgray;
}

agm-map {
  height: 400px;
}

ngb-carousel .picsum-img-wrapper {
  position: relative;
  height: 0;
  padding-top: 55%; /* Keep ratio for 900x500 images */
}

ngb-carousel .picsum-img-wrapper>img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
